import {
  Button,
  Spin,
  PageHeader,
  Select,
  Typography,
  Layout,
  message,
  Tabs,
} from 'antd';
import { useState, useMemo, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';

const { Title } = Typography;

const templates = [
  { label: 'S25 General Fabric', value: 'S25/GeneralFabric' },
  { label: 'S25 Hard Surface', value: 'S25/HardSurface' },
  { label: 'S25 Metal', value: 'S25/Metal' },
  { label: 'S25 Velvet', value: 'S25/Velvet' },
  {
    label: 'S21 Updated Mura General Fabrics',
    value: 'Mura/s21_general',
  },
  {
    label: 'S21 Updated Mura Velvet Fabrics',
    value: 'Mura/s21_velvvet',
  },
  { label: 'Mura General Fabrics', value: 'Mura/general_new' },
  { label: 'F24 Leather', value: 'Mura/chester_leather' },
  { label: 'Mura Velvet', value: 'Mura/velvet' },
  { label: 'Vrscene', value: 'vrscene' },
];

const fabricNodes = [];
const legNodes = [];

function App() {
  const [template, setTemplate] = useState(null);
  const [asset, setAsset] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('material');
  const fetchRef = useRef(0);
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get('orgId');
  const token = urlParams.get('token');
  const fetchOptions = async (value) => {
    let url = `https://material-uploader.mythreekit.com/api/assets?type=item&orgId=${orgId}&token=${token}`;
    if (value) url += `&nameLike=${value}`;
    const results = await fetch(url).then((res) => {
      if (res.status === 200) return res.json();
      return null;
    });
    if (results) {
      return results.assets.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
  };

  const debounceTimeout = 800;

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        if (newOptions) {
          setOptions(newOptions);
        }
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    debounceFetcher();
  }, []);

  const onUpload = () => {
    const isMaterial = activeTab === 'material';
    if (!asset || (!template && isMaterial)) {
      message.error(
        `${isMaterial ? 'Template and' : ''} Catalog item are required`
      );
      return;
    }
    document.getElementById('upload').click();
  };

  const importAsset = async (e) => {
    setLoading(true);
    const data = new FormData();
    data.append('orgId', orgId);
    data.append('template', template);
    data.append('assetId', asset);
    const type = activeTab;
    for (const file of e.target.files) {
      data.append(`file`, file);
    }
    const res = await fetch(`/api/${type}/upload?token=${token}`, {
      body: data,
      method: 'POST',
    });
    setLoading(false);
    message.success('Import job submitted');
  };

  return (
    <Layout style={{ height: '100%' }}>
      <PageHeader
        style={{ background: '#fff' }}
        title={
          <div style={{ display: 'flex' }}>
            <img src="https://crateandbarrelcgi.mythreekit.com/img/cratelogo.png" />
            <div style={{ margin: 'auto', marginLeft: 20 }}>Asset Uploader</div>
          </div>
        }
      />
      <Layout.Content
        style={{ height: '100%', height: `calc(100% - 72px)`, marginLeft: 30 }}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setAsset(null);
            setActiveTab(key);
          }}
        >
          <Tabs.TabPane tab="Material" key="material">
            <div style={{ marginTop: 50, padding: 30 }}>
              <Title level={5}> Material Template </Title>
              <Select
                style={{ width: 380 }}
                onChange={setTemplate}
                options={templates}
                value={template}
              />
            </div>

            <div style={{ padding: 30 }}>
              <Title level={5}> Catalog Item </Title>
              <Select
                filterOption={false}
                showSearch={true}
                style={{ width: 380 }}
                onSearch={debounceFetcher}
                onChange={setAsset}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Model" key="model">
            <div style={{ padding: 30 }}>
              <Title level={5}> Catalog Item </Title>
              <Select
                filterOption={false}
                showSearch={true}
                style={{ width: 380 }}
                onSearch={debounceFetcher}
                onChange={setAsset}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <div style={{ padding: 30 }}>
          <Button
            style={{ background: '#1BA17B', color: '#fff', borderRadius: 4 }}
            onClick={onUpload}
          >
            Upload
          </Button>
          <input
            id="upload"
            onChange={importAsset}
            type="file"
            style={{ display: 'none' }}
          />
        </div>
        {loading && <Spin style={{ padding: 20 }} tip="Uploading..."></Spin>}
      </Layout.Content>
    </Layout>
  );
}

export default App;
